import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { Sidebar } from "./components/Sidebar";

const MainLayout = () => {
  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Sidebar />

          <div className="layout-page">
            <Navbar />

            <div className="main-content-container">
              <Outlet />

              {/* <footer className="content-footer footer bg-footer-theme">
                        <div className="container-xxl">
                            <div
                                className="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
                                <div>
                                    ©
                                    <script>
                                        document.write(new Date().getFullYear());
                                    </script>
                                    , LaraDbLogger
                                </div>
                            </div>
                        </div>
                    </footer>   */}

              <div className="content-backdrop fade"></div>
            </div>
          </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>

        <div className="drag-target"></div>
      </div>

      {/* <div> 
        <Header /> 
      
 
        <Footer />
      </div> */}
    </>
  );
};

export default MainLayout;
