import React from "react";
import { Link } from "react-router-dom";

export const SingleMangaCard = () => {
  return (
    <>
      {" "}
      <div className="ps-single-manga">
        <div className="ps-single-manga--img">
          <img
            src="./images/temp/00-cover.jpg"
            className="img-fluid"
            alt="Slider"
          />
          <div className="ch-genre">Manhwa</div>

          <Link>
            <div className="ch-single ch-one d-flex justify-content-between align-items-center">
              <span class="left d-flex align-items-center">CH. 10</span>
              <span class="right d-flex align-items-center">
                <i className="ti ti-alarm me-1"></i>
                <span>1 min ago</span>
              </span>
            </div>
          </Link>
          <Link>
            <div className="ch-single ch-two d-flex justify-content-between align-items-center">
              <span class="left d-flex align-items-center">CH. 10</span>
              <span class="right d-flex align-items-center">
                <i className="ti ti-alarm me-1"></i>
                <span>1 min ago</span>
              </span>
            </div>
          </Link>
          <Link>
            <div className="ch-single ch-three d-flex justify-content-between align-items-center">
              <span class="left d-flex align-items-center">CH. 10</span>
              <span class="right d-flex align-items-center">
                <i className="ti ti-alarm me-1"></i>
                <span>1 min ago</span>
              </span>
            </div>
          </Link>
        </div>
        <div className="ps-single-manga--title">
          <Link>Return Of The Shattered Constellation</Link>
        </div>
        {/* <div className="ps-single-manga--footer">
          <i className="ti ti-alarm me-1"></i>
          5mins
        </div> */}
      </div>
    </>
  );
};
