import React from "react";
import { Link } from "react-router-dom";

export const Sidebar = () => {
  return (
    <>
      {/* <aside
        id="layout-menu"
        className="layout-menu menu-vertical menu bg-menu-theme"
      >
        <div className="app-brand mt-3">
          <a href="/" className="app-brand-link">
            <span className="app-brand-logo my-3">
              <img src="{{ asset('/images/logo.png') }}" height="25" alt="" />
            </span>
            <span className="app-brand-text menu-text fw-bold">
              LaraDbLogger
            </span>
          </a>

          <div className="layout-menu-toggle menu-link text-large ms-auto">
            <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle"></i>
            <i className="ti ti-x d-block d-xl-none ti-sm align-middle"></i>
          </div>
        </div>

        <div className="menu-inner-shadow"></div>

        <ul className="menu-inner py-1">
          <li className="menu-item {{ checkRequest(['issues']) ? 'active' : '' }}">
            <Link to="/" className="menu-link">
              <i className="menu-icon tf-icons ti ti-bug"></i>
              <div data-i18n="Issues">Issues</div>
            </Link>
          </li>
          <li className="menu-item {{ checkRequest(['projects']) ? 'active' : '' }}">
            <a
              href="{{ route('backend.projects.index') }}"
              className="menu-link"
            >
              <i className="menu-icon tf-icons ti ti-brand-tabler"></i>
              <div data-i18n="Projects">Projects</div>
            </a>
          </li>
        </ul>
      </aside> */}
    </>
  );
};
