import React from "react";
import { Link } from "react-router-dom";
import { SingleMangaCard } from "../../../components/SingleMangaCard";

export const Latest = () => {
  return (
    <>
      <div className="ps-home-latest-container">
        <div className="card card-body">
          <div className="ps-home-latest-header d-flex justify-content-between px-2  py-2">
            <div>Latest Update</div>
            <div>
              <Link className="btn btn-primary btn-sm">View All</Link>
            </div>
          </div>
          <div className="ps-home-latest-content px-2">
            <div className="row">
              {[1, 2, 2, 2, 2, 2, 2].map((d) => {
                return (
                  <div className="col-6 col-sm-4 col-md-2 mt-3 px-1">
                    {/* <div className="col-6 col-sm-4 col-md-2 px-1 mt-2"> */}
                    <SingleMangaCard />
                  </div>
                );
              })}
            </div>
          </div>
          {/* test  */}
          <img
            src="https://cdn.hxmanga.com/file/majekayoo/return-of-the-shattered-constellation/chapter-86/02.jpg"
            alt=""
          />
        </div>
      </div>
    </>
  );
};
