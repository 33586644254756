import React from "react";
import { Slider } from "./components/Slider";
import { Latest } from "./components/Latest";

const Home = () => {
  return (
    <>
      <Slider />
      <Latest />

      <div className="d-none">
        <div className="card mb-4">
          <div className="card-body">
            <p className="card-text">
              This is some text within a card body. Jelly lemon drops tiramisu
              chocolate cake cotton candy soufflé oat cake sweet roll. Sugar
              plum marzipan dragée topping cheesecake chocolate bar. Danish
              muffin icing donut.
            </p>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-body">
            <p className="card-text">
              This is some text within a card body. Jelly lemon drops tiramisu
              chocolate cake cotton candy soufflé oat cake sweet roll. Sugar
              plum marzipan dragée topping cheesecake chocolate bar. Danish
              muffin icing donut.
            </p>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-body">
            <p className="card-text">
              This is some text within a card body. Jelly lemon drops tiramisu
              chocolate cake cotton candy soufflé oat cake sweet roll. Sugar
              plum marzipan dragée topping cheesecake chocolate bar. Danish
              muffin icing donut.
            </p>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-body">
            <p className="card-text">
              This is some text within a card body. Jelly lemon drops tiramisu
              chocolate cake cotton candy soufflé oat cake sweet roll. Sugar
              plum marzipan dragée topping cheesecake chocolate bar. Danish
              muffin icing donut.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
