import React from "react";
import { Link } from "react-router-dom";
// import logo from "./logo.png";

export const Navbar = () => {
  //   function openSidebar() {
  //     console.log("sideBar open");
  //   }
  return (
    <>
      <div className="ps-navbar-container py-2 px-1">
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/">
            <div className="ps-navbar-brand d-flex align-items-center">
              <img src="./logo.png" alt="Logo" />
              <div className="brand-name">PotatoScan</div>
            </div>
          </Link>
          <div className="d-flex">
            <div className="nav-link me-2">
              <i className="ti ti-search"></i>
            </div>
            <div className="nav-link me-2">
              <i className="ti ti-bell"></i>
            </div>
            <div className="nav-link me-2">
              <img
                src="./images/default-avatar.jpg"
                alt="Default Avatar | PotatoScan"
                className="ps-default-avatar rounded-circle"
              />
            </div>
          </div>
        </div>
        <div className="ps-navbar-link-container d-flex justify-content-between align-items-center mt-2 px-2">
          <Link to="/">
            <div className="d-flex align-items-center">
              <i className="ti ti-home-2 me-1"></i>
              <span>Home</span>
            </div>
          </Link>
          <Link to="/">
            <div className="d-flex align-items-center">
              <i className="ti ti-snowflake me-1"></i>
              <span>Latest</span>
            </div>
          </Link>
          <Link to="/">
            <div className="d-flex align-items-center">
              <i className="ti ti-book-2 me-1"></i>
              <span>Browse</span>
            </div>
          </Link>
          <Link to="/">
            <div className="d-flex align-items-center">
              <i className="ti ti-book-2 me-1"></i>
              <span>History</span>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};
