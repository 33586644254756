import { BrowserRouter, Routes, Route } from "react-router-dom";

//-- Pages
import MainLayout from "./pages/layout/MainLayout";
import Home from "./pages/home/index";
//-- Custom scss
import "./scss/app.scss";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
