import React from "react";
import { Link } from "react-router-dom";

export const SliderSingleSlide = () => {
  return (
    <>
      {" "}
      <div className="ps-home-slider-single">
        <div className="slider-shadow"></div>
        <div className="slider-background">
          <img src="./images/temp/00-cover.jpg" alt="Slider" />
        </div>
        <div className="slider-content d-flex justify-content-between">
          <div className="row">
            <div className="col-9 p-0">
              <div className="manga-title text-capitalize">
                <Link to="/">Return Of The Shattered Constellation</Link>
              </div>
              <div className="manga-main-genre text-uppercase">Manhwa</div>

              {/* <div className="manga-summary-title text-uppercase">
                  Summary
                </div> */}
              <div className="manga-summary-content">
                A mere human who ascended to the position of a god, ‘Twilight of
                the Gods’. After becoming infamous as an Evil God, he lost
                everything. His Constellation, his faith, and his status. His
                divinity got cut off, and his divine power disappeared.
              </div>
              {/* <div className="manga-genres text-capitalize d-flex ">
                  <span className="badge rounder-pill bg-glow">Adventure</span>
                  <span className="badge rounder-pill bg-glow">Adventure</span>
                  <span className="badge rounder-pill bg-glow">Adventure</span>
                  <span className="badge rounder-pill bg-glow">Adventure</span>
                  <span className="badge rounder-pill bg-glow">Adventure</span> 
                </div> */}
            </div>
            <div className="col-3 p-0">
              <div className="manga-poster">
                <img src="./images/temp/00-cover.jpg" alt="Slider" />
              </div>
            </div>
          </div>
          {/* <div className="slider-main-content">
            </div> */}
        </div>
      </div>
    </>
  );
};
