import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { SliderSingleSlide } from "./SliderSingleSlide";

export const Slider = () => {
  return (
    <>
      <div className="ps-home-slider-container mt-2">
        <Carousel
          showArrows={true}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          swipeable={true}
          emulateTouch={true}
        >
          <SliderSingleSlide />
          <SliderSingleSlide />
          <SliderSingleSlide />
          <SliderSingleSlide />
          <SliderSingleSlide />
          <SliderSingleSlide />
          <SliderSingleSlide />
        </Carousel>
      </div>
    </>
  );
};
